import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import Layout from '../../components/layout'
import Header from '../../components/header'
import Container from '../../components/container'
import StoryIntro from '../../components/story-intro'

SwiperCore.use([Navigation, Pagination])

const PageHeader = <Header title="Door control module" />

const TuerbedienmodulPage = ({ location }) => {
    const images = useStaticQuery(graphql`
      {
        allFile(filter: {relativeDirectory: {eq: "stories/tuerbedienmodul"}}, sort: {fields: name}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                width: 1050
                height: 650
                transformOptions: {
                  cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            name
          }
        }
      }
    `)

    return (
        <Layout translatedPageLinks={[{ code: 'de', link: '/de/story/tuerbedienmodul/' }, { code: 'en', link: '/en/story/door-control-module/' }]} pageLang="en" pageTitle="Door control module" pageHeader={PageHeader} location={location}>
            <Container>
                <section>
                    <StoryIntro>
                        <p>
                            Touch controls are ubiquitous in an increasingly technology-focused world. 
                            They combine aesthetics and comfort. 
                            At the same time, operating them – without taking your eyes off the road – becomes a challenge for the driver of a modern car. 
                            GE-T has already researched and developed intelligent solutions to this problem.
                        </p>
                    </StoryIntro>
                    <p>
                        Beyond the haptic feedback of the <Link to="/en/story/touch-steering-wheel/">multifunction touch steering wheel</Link> in the Volkswagen ID.3 and ID.4, GE-T is also re-imagining the door control module. 
                        This project was entirely managed by GE-T, from the initial ideas stage, through various prototypes, to the near-series control unit.
                    </p>
                    <p>
                        In addition to the illuminated and three-dimensionally shaped IML plastic components, GE-T also makes use of touch surfaces with haptic feedback. 
                        The active feedback to the user particularly suits the application in a vehicle. 
                        The feedback experience – in combination with the structures integrated into the surface for finding the corresponding control panel – enables operation by the driver even in limited vision situations.
                    </p>
                    <p>
                        This pioneering concept ensures protection at all times against operating errors with the simultaneous activation of two geometrically separated control surfaces: 
                        the active double touch replaces the previously common "pull-to-close" function and thus ensures the highest standards of operating safety of the window controls for the benefit of all occupants of a modern vehicle.
                    </p>
                    <Swiper
                        navigation
                        loop
                        pagination={{ clickable: true }}
                    >
                        {/* eslint-disable-next-line */}
                        {images.allFile.nodes.map((image) => <SwiperSlide key={image.name}><GatsbyImage image={getImage(image)} alt="" /></SwiperSlide>)}
                    </Swiper>
                </section>
            </Container>
        </Layout>
    )
}

export default TuerbedienmodulPage